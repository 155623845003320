<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="headerInfo">
        <el-breadcrumb-item v-for="(item, index) in arr" :key="index"  @click.native="jumpUp(item)">{{
          item
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ShopHead",
  data() {
    return {
      // arr:["所有商品","一级分类"]
      arr: ["首页"],
    };
  },
  props:{
    productName:{
         type:String
    }
  },
  watch:{
    productName:{
      deep:true,
      handler:function(){
        this.arr.push(this.productName)
      }
    }
  },
  created(){
    if(this.productName!=""){
       this.arr.push(this.productName)
    }
  },
  components: {},
  methods: {
    jumpUp(name){
      if(name==="首页"){
         this.$router.push({ path: "/home" });
      }
      console.log(name)
      if(name==="超级秒杀"){
          //    alert("超级秒杀")
          this.$router.push({name:"ActivityModuleList",query:{ActivityType:2}})
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.headerInfo {
  padding-bottom: 16px;
}
/deep/.el-breadcrumb__item{
  cursor: pointer;
}
</style>
